import type { SearchResultFragment } from '#graphql-operations'

export function calculateDiscountPercentage(priceBeforeDiscount?: Nullable<number>, currentPrice?: Nullable<number>) {
  if (priceBeforeDiscount && currentPrice && priceBeforeDiscount > currentPrice) {
    return Math.round(((priceBeforeDiscount - currentPrice) / priceBeforeDiscount) * 100)
  }
  return 0
}

export function getDiscountPercentage(product: SearchResultFragment) {
  return calculateDiscountPercentage(
    product.customProductMappings?.priceBeforeDiscount,
    product.priceRange
      ? 'value' in product.priceRange
        ? product.priceRange.value
        : product.priceRange.min
      : undefined,
  )
}
